angular.module('enervexSalesappApp').controller('ApplianceCtrl', function($scope, $uibModalInstance, _, Auto, setValue, paint, ApplianceModel, ApplianceCompany, Util, checkPoint, $q, ProductSubtype, Product,SelectionMetaUtil, Manufacturer, CommandManager) {
	var checkObj = {},
		checkObjSelectionMeta = {},
		enforcePrecision = true;
	var prevWatch = null, prevWatchSelectionMeta = null;
	Manufacturer.query().$promise.then(function(result){
		$scope.manufacturers = result
		$scope.manufacturer = _.find(result, function(o){
			return o.isDefault
		})
	})
	$scope.isDry = function(){
		var result = Util.isDry($scope.appliance, $scope.design)
		console.log("isDry", result)
		return result
	}
	$scope.isBwh = function() {
		var result = Util.isBwh($scope.appliance, $scope.design)
		console.log("isBwh", result)
		return result
	}
	function _setup() {
		if ($scope.isBwh()) {
			$scope.applianceTabHead = 'Appliance';
		} else if ($scope.design.application.computeAs === "DRY") {
			$scope.applianceTabHead = $scope.design.application.name + ' Data'
		}
		$scope.cmbOutletShapeOptions = [{
			text: "Round",
			value: "ROU"
		}, {
			text: "Square",
			value: "SQU"
		}, {
			text: "Rectangular",
			value: "REC"
		}, {
			text: "Oval",
			value: "OVA"
		}];
		$scope.BaroOptions = ["No", "Yes"];
		$scope.cmbOutletDampOptions = ["No", "Yes"];
		$scope.cmbOpsModeOptions = ["Normal", "Standby"];
		$scope.PresTypeOptions = ["in.W.C.", "pa"];
		$scope.cmbOutDimTypeOptions = ["in", "mm"];
		$scope.cmbInpUnitOptions = ["MBTU", "kW"];
		$scope.cmbTempUnitOptions = ["&deg;F", "&deg;C"];
		$scope.lstIconOptions = _.filter($scope.icons, function(icon){
			return icon.iconType == "Appliance"
		})
		$scope.startingConfigs = [
			"type",
			"product"
		]
		return ApplianceCompany.query({
			limit:300,
			active: true,
			applications: $scope.design.application._id
		}).$promise.then(function(res) {
			$scope.applianceCompanies = res;
			$scope.startingSubtypes = Util.getSubtypes("StartingPoint", $scope.allProductSubtypes,$scope.design)
			return $q(function(resolve, reject) {
				resolve()
			})
		})
	}
	function _load(payload) {
		$scope.appliance = payload;
		var applianceCompany = getApplianceCompany();
		if (applianceCompany){
			$scope.appliance.applianceCompany = applianceCompany._id;
			getApplianceModels(false)
		}
		if (prevWatch) {
			prevWatch()
		}
		prevWatch = $scope.$watchCollection("appliance", function(newObj, oldObj){
			_.each(_.keys(newObj), function(key) {
				var newVal = newObj[key],
					oldVal = oldObj[key];
				if (newVal != oldVal){
					if (key == "ID" || newObj.ID != payload.ID){
						return
					}
					checkObj[key] = true;
				}
			})
		})
	}
	function load(appliances) {
		$scope.selectedAppliances = appliances;
		$scope.selectedAppl = [];
		$scope.multiFits = $scope.fittings;
		$scope.appliance = {};

		_.each($scope.selectedAppliances, function(appl) {
			$scope.selectedAppl.push(appl.ID);
			if (appl.Fit1 != 'STP') {
				$scope.selectedJoints.push(appl)
			}
		})


		var payload = {}
		var first = $scope.selectedAppliances[0].Appl;
		var ignore = ['startingConfig', 'productSubtype','startingPointProduct']
		_.each(_.keys(first), function(key){
			if (_.contains(ignore, key)) {
				return
			}
			var value = first[key];
			var diff = _.find($scope.selectedAppliances, function(o){
				return !o.Appl || o.Appl[key] != first[key]
			})
			if (!diff){
				if (key == "Volume" || key == "OutDia"|| key=="PresL" || key=="PresH"){
					payload[key] = Util._float(value)
				} else {
					payload[key] = value
				}
			}
		})
		if ($scope.isBwh()) {
			if (payload.InputType == "kW") {
				payload.InputL = mb2kw(payload.InputL)
				payload.InputH = mb2kw(payload.InputH)
			}
			if (payload.TempType == "°C") {
				payload.TempL = f2c(payload.TempL)
				payload.TempH = f2c(payload.TempH)
			}
			if (payload.PresType == "pa") {
				payload.PresL = in2pa(payload.PresL);
				payload.PresH = in2pa(payload.PresH);
			}
			if (payload.OutDimType == "mm") {
				payload.OutDim1 = in2mm(payload.OutDim1);
				payload.OutDim2 = in2mm(payload.OutDim2);
			}
		} else {
			payload.InputType = "CFM";
			payload.PresT = 0;
			payload.PresHType = "in.W.C.";
			payload.PresLType = "in.W.C.";
			payload.PresType = "in.W.C.";
		}

		if (appliances && appliances.length == 1) {
			$scope.selectionMeta = SelectionMetaUtil.getSelectionMeta(appliances[0], $scope.design, null)
			if ($scope.selectionMeta.startingPointProduct) {
				Product.get({
					id: $scope.selectionMeta.startingPointProduct
				}).$promise.then(function(product){
					$scope.selectedStartingProduct = product
					$scope.selectedStartingProductNavId = product && product.navId
					_load(payload);
				})
			} else {
				_load(payload);
			}
		} else {
			// $scope.selectionMeta = {}
			_load(payload);
		}

	}
	_setup().then(function() {
		load($scope.selectionManager.selectedAppliances())
	})
	$scope.changeApplManu = function() {
		var applianceCompany = getApplianceCompany()
		if (!applianceCompany) {
			return;
		}
		$scope.appliance.ManuID = applianceCompany.id;
		$scope.appliance.Manufacturer = applianceCompany.company;
		getApplianceModels(applianceCompany);
	}
	function getApplianceCompany() {
		var result = _.find($scope.applianceCompanies, function(o) {
			if ($scope.appliance.applianceCompany){
				return o._id == $scope.appliance.applianceCompany;
			}
			return o.id == $scope.appliance.ManuID
		})
		if (!$scope.appliance.applianceCompany && result){
			$scope.appliance.applianceCompany = result._id
		}
		if (!$scope.appliance.ManuID && result){
			$scope.appliance.ManuID = result.id
		}
		return result;
	}
	$scope.includeIcon = function(icon) {
		if (icon.showInPallete) {
			return true
		} else {
			if ($scope.selectedApplianceModel && $scope.selectedApplianceModel.icon && $scope.selectedApplianceModel.icon._id == icon._id){
				return true
			}
		}
	}
	function getApplianceModel() {
		if (!$scope.appliance) {
			$scope.appliance = {}
		}
		var result = _.find($scope.applianceModels, function(o) {
			if ($scope.appliance.applianceModel){
				return o._id == $scope.appliance.applianceModel;
			}
			return o.id == $scope.appliance.ModelID
		})
		if (result) {
			$scope.selectedApplianceModel = result
		}
		if (!$scope.appliance.applianceModel && result){
			$scope.appliance.applianceModel = result._id
		}
		if (!$scope.appliance.ModelID && result){
			$scope.appliance.ModelID = result.id
		}
		return result;
	}
	$scope.getApplianceModel = getApplianceModel;

	function getFuelData() {
		var applianceModel= getApplianceModel()
		if (!applianceModel){
			return
		}
		var result = _.find(applianceModel.fuels, function(fuelData){
			if ($scope.appliance.fuel){
				return fuelData.fuel._id == $scope.appliance.fuel
			}
			return fuelData.fuel.code == $scope.appliance.FuelCode
		})
		if (!$scope.appliance.fuel && result){
			$scope.appliance.fuel = result.fuel._id
		}
		if (!$scope.appliance.FuelCode && result){
			$scope.appliance.FuelCode = result.fuel.code
		}
		return result;
	}
	function setFuelNew(fuelDatas){
		var options = _.filter(fuelDatas, function(fuelData){
			return fuelData.include != false && fuelData.fuel.active == true
		})
		options = _.sortBy(options, function(f){
			return f.fuel.sortOrder*-1;
		});		
		$scope.cmbFuel.options = options;
	}
	$scope.selectFuel = function(){
		try {
			var fuelData = getFuelData();
			if (fuelData){
				_setApplianceModel(getApplianceModel())
				_setFuel(fuelData)
			}
		}catch(e){
			console.log("error in selectFuel()", e, fuelData)
		}
	}
	function _setFuel(fuelData){
		if (!fuelData){
			return
		}
		$scope.appliance.FuelCode = fuelData.fuel.code
		if ($scope.appliance.InputType == "KW") {
			$scope.appliance.InputL = mb2kw(fuelData.mbhmin);
			$scope.appliance.InputH = mb2kw(fuelData.mbhmax);
		} else {
			$scope.appliance.InputL = fuelData.mbhmin;
			$scope.appliance.InputH = fuelData.mbhmax;
		}
		$scope.appliance.CO2L = (enforcePrecision) ? Util.enforcePrecision(fuelData.co2min,2) : fuelData.co2min;
		$scope.appliance.CO2H = (enforcePrecision) ? Util.enforcePrecision(fuelData.co2,2) : fuelData.co2;;
		if ($scope.appliance.TempType == "°C") {
			$scope.appliance.TempL = f2c(fuelData.tempmin);
			$scope.appliance.TempH = f2c(fuelData.temp);
		} else {
			$scope.appliance.TempL = fuelData.tempmin;
			$scope.appliance.TempH = fuelData.temp;
		}
	}
	function _setApplianceModel(applianceModel, fuelData){
		$scope.appliance.Cat = applianceModel.cat;
		$scope.appliance.Baro = Util.toYesNo(applianceModel.baro);
		$scope.appliance.OutletDamp = Util.toYesNo(applianceModel.outletdamper);
		$scope.appliance.cfm = applianceModel.cfm;

		if ($scope.appliance.PresType == "pa") {
			$scope.appliance.PresL = in2pa(Util._round(applianceModel.presmin));
			$scope.appliance.PresH = in2pa(Util._round(applianceModel.presmax));
		} else {
			$scope.appliance.PresL = Util._round(applianceModel.presmin);
			$scope.appliance.PresH = Util._round(applianceModel.presmax);
		}
		if ($scope.appliance.OutDimType == "mm") {
			$scope.appliance.OutDim1 = in2mm(applianceModel.outletdia);
		} else {
			$scope.appliance.OutDim1 = applianceModel.outletdia;
		}
	}
	$scope.changeApplModel = function(set) {
		var applianceModel = getApplianceModel()
		if (!applianceModel) {
			return;
		}
		$scope.appliance.Model = applianceModel.model;
		$scope.applianceModel = applianceModel._id;
		// setFuel(applianceModel.fuel.substr(0, 1))
		if (set) {
			$scope.appliance.FuelCode = null
			$scope.appliance.fuel = null
		}
		if ($scope.isBwh()) {
			setFuelNew(applianceModel.fuels);
			var fuelData = getFuelData()
			if (!fuelData){
				$scope.appliance.fuel = $scope.cmbFuel.options[0].fuel._id;
			}
			if (set !== false){
				$scope.selectFuel()
			}
		}
		var icon = _.find($scope.lstIconOptions, function(o){
			return applianceModel.icon && o._id == applianceModel.icon._id
		})
		$scope.selectIcon(icon, set)
		if (set == true) {
			checkObj.startingConfig = true
			checkObj.productSubtype = true
			checkObj.startingPointProduct = true
			checkObj.isPlumeAbatement = true
			checkObj.computeAs = true
			$scope.appliance.computeAs = applianceModel.computeAs
			$scope.appliance.isPlumeAbatement = applianceModel.isPlumeAbatement
			$scope.appliance.startingConfig = applianceModel.startingConfig
			$scope.appliance.productSubtype = applianceModel.productSubtype
			$scope.appliance.startingPointProduct = applianceModel.startingPointProduct
			$scope.appliance.startingPointProductNavId = applianceModel.startingPointProduct && applianceModel.startingPointProduct.navId
			if ($scope.appliance.isPlumeAbatement) {
				$scope.appliance.Volume = applianceModel.volume;
			}
			if ($scope.isDry()) {
				$scope.appliance.Volume = applianceModel.volume;
				$scope.appliance.PresL = applianceModel.presmin;
				$scope.appliance.PresH = applianceModel.presmax;
				$scope.appliance.OutDia = applianceModel.outletdia;
			}
			computeInputs()
		}
	}
	$scope.changed = function() {
		computeInputs()
	}
	function computeInputs() {
		if (!$scope.appliance.isPlumeAbatement) {
			return
		}
		var constant = 0.1
		if ($scope.appliance.Cat == "I - Draft Hood") {
			constant = 0.9
		}
		var BaroPress = 29.92
		var AmbOutMax = $scope.job.environment.ambientOutMax;
		var Pm = (1.325 * BaroPress) / (AmbOutMax + 460)
		var maxflowConstant = 1
		// var maxflowConstant = null
		// if ($scope.design.include.supply) {
			// if ($scope.appliance.Cat == "I - Draft Hood") {
			// 	maxflowConstant = 0.9
			// } else {
			// 	maxflowConstant = 0.1
			// }
		// } else {
		// 	maxflowConstant = 1
		// }

		var Massflow = $scope.appliance.Volume * (60 * Pm)
		console.log("Massflow",Massflow)
		var fuelObj = getFuelData()
		var fuel = fuelObj.fuel
		checkObj.InputL = true
		checkObj.InputH = true
		$scope.appliance.InputL = Massflow/(mper1000(fuel, $scope.appliance.CO2L) * maxflowConstant)
		$scope.appliance.InputH = Massflow/(mper1000(fuel, $scope.appliance.CO2H) * maxflowConstant)
	}
	function mper1000(fuel, CO2) {
		return fuel.MassNo1 * (fuel.MassNo2 + (fuel.MaxCo2 / CO2));
	}
	function getApplianceModels(set) {
		ApplianceModel.query({
			active: true,
			applianceCompany:$scope.appliance.applianceCompany, 
			applications: $scope.design.application._id,
			limit:1000
		}).$promise.then(function(res) {
			$scope.applianceModels = res;
			var applianceModel = getApplianceModel()
			if (applianceModel){
				$scope.changeApplModel(set)
			}
		})
	}
	$scope.selectIcon = function(option, set) {
		if (!set) {
			return true
		}
		if (!option) {
			option = _.find($scope.lstIconOptions, function(o){
				return o.showInPallete
			})
		}
		$scope.selectedIcon = option;
		$scope.appliance.Icon = option.code;
	}
	function _setProps() {
		var appl = Util.createAppliance($scope.design)
		var multiSelect = false;
		if ($scope.selectedAppl.length > 1) {
			multiSelect = true;
		}

		appl.isPlumeAbatement = $scope.appliance.isPlumeAbatement
		var names = _.filter(_.keys($scope.appliance),function(key) {
			return key.indexOf("plume" == 0)
		})
		console.log("setting plume fields", names)
		_.each(names, function(name){
			appl[name] = $scope.appliance[name]
		})

		if ($scope.isBwh()) {
			appl.Manufacturer= $scope.appliance.Manufacturer;
			appl.Model = $scope.appliance.Model;
			appl.ManuID = $scope.appliance.ManuID;
			appl.ModelID = $scope.appliance.ModelID;
			appl.Label = $scope.appliance.Label;
			appl.Cat = $scope.appliance.Cat;
			appl.InputType = $scope.appliance.InputType;
			appl.CO2L = (enforcePrecision) ? Util.enforcePrecision(Util._float($scope.appliance.CO2L),2) : Util._float($scope.appliance.CO2L);
			appl.CO2H = (enforcePrecision) ? Util.enforcePrecision(Util._float($scope.appliance.CO2H),2) : Util._float($scope.appliance.CO2H);
			appl.TempType = $scope.appliance.TempType;
			appl.FuelCode = $scope.appliance.FuelCode;
			appl.FuelOpt = $scope.appliance.FuelOpt;
			appl.PresType = $scope.appliance.PresType;
			appl.OutShape = $scope.appliance.OutShape;
			appl.OutDimType = $scope.appliance.OutDimType;
			appl.Baro = $scope.appliance.Baro;
			appl.OutletDamp = $scope.appliance.OutletDamp;
			appl.OpsModeCode = $scope.appliance.OpsModeCode;
			appl.Icon = $scope.appliance.Icon;
			appl.Volume = Util._float($scope.appliance.Volume);
			appl.OutDia = Util._float($scope.appliance.OutDia);

			if (appl.InputType == "kW") {
				appl.InputL = kw2mb(Util._float($scope.appliance.InputL))
				appl.InputH = kw2mb(Util._float($scope.appliance.InputH))
			} else {
				appl.InputL = Util._float($scope.appliance.InputL)
				appl.InputH = Util._float($scope.appliance.InputH)
			}
			if (appl.TempType == "°C") {
				appl.TempL = c2f(Util._int($scope.appliance.TempL))
				appl.TempH = c2f(Util._int($scope.appliance.TempH))
			} else {
				appl.TempL = Util._int($scope.appliance.TempL)
				appl.TempH = Util._int($scope.appliance.TempH)
			}
			if (appl.PresType == "pa") {
				appl.PresL = pa2in(Util._round($scope.appliance.PresL))
				appl.PresH = pa2in(Util._round($scope.appliance.PresH))
			} else {
				appl.PresL = Util._round($scope.appliance.PresL)
				appl.PresH = Util._round($scope.appliance.PresH)
			}
			if (appl.OutDimType == "mm") {
				appl.OutDim1 = mm2in(Util._float($scope.appliance.OutDim1))
				appl.OutDim2 = mm2in(Util._float($scope.appliance.OutDim2))
			} else {
				appl.OutDim1 = Util._float($scope.appliance.OutDim1)
				appl.OutDim2 = Util._float($scope.appliance.OutDim2)
			}
			//Validate
			if (checkObj.InputH && appl.InputH == 0) {
				alert("Input High cannot be 0")
				return false;
			}
			if (appl.InputL == 0) {
				appl.InputL = appl.InputH
			}
			if ((checkObj.InputL || checkObj.InputH) && appl.InputL > appl.InputH) {
				alert("Input High must be higher than Input Low")
				return false;
			}
			if (checkObj.C02H && (appl.CO2H == 0 || appl.CO2H > 20)) {
				alert("CO2% must be higher than 0 and lower than 20.")
				return false;
			}
			if (appl.CO2L == 0 || appl.CO2L > 20) {
				appl.CO2L = appl.CO2H
			}
			if ((checkObj.PresH || checkObj.PresL) && appl.PresH < appl.PresL) {
				alert("Outlet Pressure High must be higher than Outlet Pressure Low.")
				return false;
			}
			if (checkObj.OutDim1 && appl.OutDim1 < 2) {
				alert("Outlet diameter must be at least 2 in.")
				return false;
			}
		} else if ($scope.design.application.computeAs === "DRY") {
			appl.Volume = Util._float($scope.appliance.Volume);
			appl.InputType = "CFM";
			appl.Label = $scope.appliance.Label;
			appl.PresL = Util._float($scope.appliance.PresL);
			appl.PresH = Util._float($scope.appliance.PresH);
			appl.PresT = 0;
			appl.PresHType = "in.W.C.";
			appl.PresLType = "in.W.C.";
			appl.PresType = "in.W.C.";
			appl.OutDia = Util._float($scope.appliance.OutDia);
			appl.Icon = $scope.appliance.Icon;
		}

		var applianceCompany = getApplianceCompany()
		if (applianceCompany) {
			appl.Manufacturer = applianceCompany.company
			appl.applianceCompany = applianceCompany._id
		}

		var applianceModel = getApplianceModel()
		if (applianceModel) {
			appl.Model = applianceModel.model
			appl.applianceModel = applianceModel._id
		}

		console.log("setting",_.keys(checkObj))
		_.each(_.keys(checkObj),function(key) {
			_.each($scope.design.fc5layout.Layout, function(sv){
				if ($scope.selectedAppl.indexOf(sv.ID) > -1) {
					sv.Appl[key] = appl[key];
				}
			})
		});

		_.each($scope.design.fc5layout.Layout, function(sv) {
			if ($scope.selectedAppl.indexOf(sv.ID) > -1) {
				sv.Appl.PresL = Util._round(sv.Appl.PresL)
				sv.Appl.PresH = Util._round(sv.Appl.PresH)
			}
		})
		// Util.dehydrateAttribute($scope.selectionMeta, "startingPointProduct")
		return true;
	}
	$scope.applyAppliance = function() {
		if (_setProps()) {
			$scope._validate();
			paint();
			CommandManager.createCommand($scope.design, {
				name: "Edited Appliance"
			}, $scope.checkDirty)
			return true;
		}
	}
	$scope.okAppliance = function() {
		if ($scope.applyAppliance()) {
			$scope._toSelectMode()
			var modalInstance = $uibModalInstance.close();
			paint();
		}
	}
	$scope.canNext = function() {
		if (!$scope.selectedAppliances) {
			return false;
		} else if ($scope.selectedAppliances.length != 1){
			return false;
		} else {
			var _next = $scope.selectionManager.nextAppliance();
			return (_next.length == 1)
		}
	}
	$scope.next = function() {
		if ($scope.applyAppliance()) {
			var _next = $scope.selectionManager.nextAppliance();
			if (_next.length == 1) {
				$scope.selectionManager.unselectAll()
				$scope.selectionManager.selectAppliance(_next[0].Appl)
				load(_next)
				paint()
			} else if (_next.length == 0){
				alert("you are at the end")
			} else {
				alert("too many next paths")
			}
		}
	}
	$scope.canPrevious = function() {
		if (!$scope.selectedAppliances) {
			return false;
		} else if ($scope.selectedAppliances.length != 1){
			return false;
		} else {
			var _previous = $scope.selectionManager.previousAppliance();
			return (_previous.length == 1)
		}
	}
	$scope.previous = function() {
		if ($scope.applyAppliance()) {
			var _previous = $scope.selectionManager.previousAppliance();
			if (_previous.length == 1) {
				$scope.selectionManager.unselectAll()
				$scope.selectionManager.selectAppliance(_previous[0].Appl)
				load(_previous);
				paint();
			} else if (_previous.length == 0){
				alert("you are at the begining")
			} else {
				alert("too many prvious paths")
			}
		}
	}
	$scope.autoCompleteOptions = {
		minimumChars: 1,
		dropdownWidth: "500px",
		containerCssClass: 'appliance-modal-autocomplete',
		data: function (term) {
			return Product.query({
				limit:20,
				term: term,
				manufacturer: $scope.manufacturer._id
			}).$promise.then(function(products){
				$scope.productsPage = products
				return _.map(products, function(product){
					return product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)
				});
			})
		},
		itemSelected: function(item){
			$scope.ctx.formChanged = true;
			var fullProduct = _.find($scope.productsPage, function(product){
				return (product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)) == item.item
			})
			$scope.selectedStartingProductNavId = fullProduct.navId
			$scope.selectedStartingProduct = fullProduct
			$scope.selectionMeta.startingPointProduct = fullProduct._id;
		}
	}
});
